import React from "react";
import styles from "./styles/Button.module.scss";
import { ReactComponent as ButtonBorder } from "./assets/images/button_border.svg";
import { Link } from "react-router-dom";
import classNames from "classnames";

const Button = ({ className, path, isOpen, title = "LEARN MORE" }) => (
  <Link
    to={path}
    className={classNames({
      [styles.root]: true,
      [styles.visible]: isOpen,
      [className]: className,
    })}
  >
    <div className={styles.button}>{title}</div>
    <div className={styles.buttonBorder}>
      <ButtonBorder />
    </div>
  </Link>
);

export default Button;
