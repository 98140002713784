import React from "react";
import styles from "./styles/Footer.module.scss";
import classNames from "classnames";
import { ReactComponent as FacebookLogo } from "./assets/images/facebook_logo.svg";
import { ReactComponent as InstagramLogo } from "./assets/images/instagram_logo.svg";
import { ReactComponent as YoutubeLogo } from "./assets/images/youtube_logo.svg";
import { ReactComponent as TwitterLogo } from "./assets/images/twitter-x_logo.svg";
import { ReactComponent as DiscordLogo } from "./assets/images/discord_logo.svg";

const Footer = () => (
  <div className={styles.root}>
    <div className={styles.container}>
      <div className={styles.copyright}>
        Copyright © {new Date().getFullYear()} Abelana VR, All rights reserved.
      </div>
      <div className={styles.links}>
        <a
          href="/terms-of-use"
          className={styles.link}
          target="_blank"
          rel="nofollow noopener noreferrer"
        >
          Terms of Use
        </a>
        <a
          href="/privacy-policy"
          className={styles.link}
          target="_blank"
          rel="nofollow noopener noreferrer"
        >
          Privacy Policy
        </a>
        <a
          href={
            "https://drive.google.com/drive/folders/1xbVlNMojhgu1CZJvLQbKR5BueUrzvM-M"
          }
          className={styles.link}
          target="_blank"
          rel="nofollow noopener noreferrer"
        >
          Press Pack
        </a>
      </div>
    </div>
    <div className={styles.socialLinks}>
      <a
        href="https://www.facebook.com/abelanavr"
        className={styles.socialButton}
        target="_blank"
        rel="nofollow noopener noreferrer"
      >
        <div className={classNames([styles.electron, styles.electronX])}></div>
        <div className={classNames([styles.electron, styles.electronY])}></div>
        <FacebookLogo />
      </a>
      <a
        href="https://www.instagram.com/abelana_vr/"
        className={styles.socialButton}
        target="_blank"
        rel="nofollow noopener noreferrer"
      >
        <div className={classNames([styles.electron, styles.electronX])}></div>
        <div className={classNames([styles.electron, styles.electronY])}></div>
        <InstagramLogo />
      </a>
      <a
        href="https://www.youtube.com/channel/UC947NvsJ5zqQmfhMSAkU-CA"
        className={styles.socialButton}
        target="_blank"
        rel="nofollow noopener noreferrer"
      >
        <div className={classNames([styles.electron, styles.electronX])}></div>
        <div className={classNames([styles.electron, styles.electronY])}></div>
        <YoutubeLogo />
      </a>
      <a
        href="https://twitter.com/AbelanaVR"
        className={styles.socialButton}
        target="_blank"
        rel="nofollow noopener noreferrer"
      >
        <div className={classNames([styles.electron, styles.electronX])}></div>
        <div className={classNames([styles.electron, styles.electronY])}></div>
        <TwitterLogo />
      </a>
      <a
        href="https://discord.gg/c4ZJ7cj9Nw"
        className={styles.socialButton}
        target="_blank"
        rel="nofollow noopener noreferrer"
      >
        <div className={classNames([styles.electron, styles.electronX])}></div>
        <div className={classNames([styles.electron, styles.electronY])}></div>
        <DiscordLogo />
      </a>
    </div>
  </div>
);

export default Footer;
