import React from "react";
import BackgroundImage from "../../../Home/components/EvolutionOfStars/assets/background.jpg";
import StarImage_1x from "../../../Home/components/EvolutionOfStars/assets/star@1x.png";
import StarImage_2x from "../../../Home/components/EvolutionOfStars/assets/star@2x.png";
import StarImage_3x from "../../../Home/components/EvolutionOfStars/assets/star@3x.png";
import BotImage_1x from "../../../Home/components/EvolutionOfStars/assets/bot@1x.png";
import BotImage_2x from "../../../Home/components/EvolutionOfStars/assets/bot@2x.png";
import BotImage_3x from "../../../Home/components/EvolutionOfStars/assets/bot@3x.png";
import Button from "../../../Home/components/Button";
import styles from "./styles/EvolutionOfStars.module.scss";

const EvolutionOfStars = () => (
  <div className={styles.root}>
    <img className={styles.background} src={BackgroundImage} alt="" />
    <img
      className={styles.star}
      src={StarImage_1x}
      srcSet={`${StarImage_1x} 1x, ${StarImage_2x} 2x, ${StarImage_3x} 3x`}
      alt=""
    />
    <img
      className={styles.bot}
      src={BotImage_1x}
      srcSet={`${BotImage_1x} 1x, ${BotImage_2x} 2x, ${BotImage_3x} 3x`}
      alt=""
    />
    <div className={styles.filter} />
    <div className={styles.content}>
      <div className={styles.title}>STELLAR SIMULATOR</div>
      {/*<div className={styles.category}>VISCERAL SCIENCE</div>*/}
      <Button
        className={styles.button}
        path="stellar-simulator"
        isOpen
        title="PLAY"
      />
    </div>
  </div>
);

export default EvolutionOfStars;
