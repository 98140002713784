import React from "react";
import styles from "./styles/Home.module.scss";
import Carousel from "./components/Carousel";
import CarouselMobile from "./components/CarouselMobile";

const Home = () => (
  <div className={styles.root}>
    <Carousel />
    <CarouselMobile />
  </div>
);

export default Home;
