import backgroundVideo from "./videos/Banner/background_video.mp4";
import snapshot from "./images/Banner/snapshot.png";
import summary_image_1x from "./images/Summary/image@1x.png";
import summary_image_2x from "./images/Summary/image@2x.png";
import summary_image_3x from "./images/Summary/image@3x.png";
import details_image_1x from "./images/Details/image@1x.png";
import details_image_2x from "./images/Details/image@2x.png";
import details_image_3x from "./images/Details/image@3x.png";
import gallery_video_1 from "./videos/Gallery/video_1.mp4";
import gallery_video_2 from "./videos/Gallery/video_2.mp4";
import gallery_video_3 from "./videos/Gallery/video_3.mp4";
import gallery_video_4 from "./videos/Gallery/video_4.mp4";
import gallery_video_5 from "./videos/Gallery/video_5.mp4";
import gallery_video_6 from "./videos/Gallery/video_6.mp4";

export const gravitySimulator = {
  banner: {
    title: "Gravity Simulator",
    snapshot: snapshot,
    backgroundVideo: backgroundVideo,
    downloadLink:
      "https://store.steampowered.com/app/3060460/Gravity_Simulator/",
    buttonTitle: "COMING SOON",
  },
  summary: {
    text: "GRAVITY SIMULATOR is a new virtual reality experience in the Simulators series by the World Science Festival. Inspired by the first observation of gravitational waves made by the LIGO observatory, in this experience students create their own systems of stars, planets, moons, and black holes, observing how they interact and collide. Students will discover the phenomena of “stellar vampirism” and gravitational waves, with spacetime distortions demonstrated in 3D. Students can peer inside planets and other objects, learning about their compositions, as well as the mechanics of volcanism and plate tectonics.",
    image_1x: summary_image_1x,
    image_2x: summary_image_2x,
    image_3x: summary_image_3x,
  },
  details: {
    image_1x: details_image_1x,
    image_2x: details_image_2x,
    image_3x: details_image_3x,
    userEnvironment: "Multiplayer",
    maximumClassSize: "34",
    availableRoles: "Teacher, students",
    pcVrVersion: "Yes",
    standaloneVrVersion: "Yes",
    mobileApp: "No",
    webApplication: null,
    videoChat: "BlueJeans",
  },
  gallery: {
    video_1: gallery_video_1,
    video_2: gallery_video_2,
    video_3: gallery_video_3,
    video_4: gallery_video_4,
    video_5: gallery_video_5,
    video_6: gallery_video_6,
  },
};
