import React, { useRef, useState } from "react";
import TextField from "@mui/material/TextField";
import styles from "./styles/Form.module.scss";
import classNames from "classnames";
import AlertError from "../../../../components/AlertError";
import AlertSuccess from "../../../../components/AlertSuccess";
import { ReactComponent as ButtonBorder } from "./assets/images/button_border.svg";

const Form = () => {
  const [loading, setLoading] = useState(false);
  const [openAlertError, setOpenAlertError] = useState(false);
  const [openAlertSuccess, setOpenAlertSuccess] = useState(false);
  const formRef = useRef(null);

  const postData = async (data = {}) => {
    let response;

    try {
      response = await fetch("/contact-form.php", {
        method: "POST",
        body: data,
      });
    } catch (error) {
      console.log("There was an error", error);
    }
    if (response?.ok) {
      setOpenAlertSuccess(true);
      formRef.current.reset();
    } else {
      setOpenAlertError(true);
      console.log(`HTTP Response Code: ${response?.status}`);
    }
    setLoading(false);
  };

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    const data = new FormData(formRef.current);
    const queryString = new URLSearchParams(data);
    postData(queryString);
  };

  return (
    <>
      <div className={styles.root}>
        <div className={styles.container}>
          <div className={styles.title}>Use this form to connect with us</div>
          <div className={styles.description}>
            Please, do not hesitate to contact us. We are always happy to meet
            with people, discuss new ideas, and push virtual reality forward!
            <br />
            And follow us on social networks below.
          </div>
          <form className={styles.form} onSubmit={handleSubmit} ref={formRef}>
            <div className={styles.inputs}>
              <div className={styles.formRow}>
                <TextField
                  className={classNames([styles.input, styles.inputName])}
                  label="Your Name"
                  name="name"
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                  required
                  fullWidth
                  margin="normal"
                  disabled={loading}
                />
                <TextField
                  className={classNames([styles.input, styles.inputEmail])}
                  label="Your Email"
                  name="email"
                  variant="standard"
                  InputLabelProps={{ shrink: true }}
                  required
                  fullWidth
                  margin="normal"
                  type="email"
                  disabled={loading}
                />
              </div>
              <TextField
                className={classNames([styles.input, styles.inputSubject])}
                label="Subject"
                name="subject"
                variant="standard"
                InputLabelProps={{ shrink: true }}
                required
                fullWidth
                margin="normal"
                disabled={loading}
              />
              <TextField
                className={classNames([styles.input, styles.inputMessage])}
                label="Your Message"
                name="message"
                variant="standard"
                InputLabelProps={{ shrink: true }}
                required
                fullWidth
                margin="normal"
                multiline
                minRows={5}
                maxRows={5}
                disabled={loading}
              />
            </div>
            <button
              className={classNames({
                [styles.buttonContainer]: true,
                [styles.disabled]: loading,
              })}
              type="submit"
            >
              <div className={styles.button}>Send</div>
              <div className={styles.buttonBorder}>
                <ButtonBorder />
              </div>
            </button>
          </form>
        </div>
        <div className={styles.info}>
          <div className={styles.location}>We are located in Brooklyn, NY</div>
          <div className={styles.email}>
            Email: <a href="mailto: dev@abelanavr.com">dev@abelanavr.com</a>
          </div>
        </div>
        <div className={styles.backgroundText}>Contact us</div>
      </div>
      <AlertError
        open={openAlertError}
        handleClose={() => setOpenAlertError(false)}
      />
      <AlertSuccess
        open={openAlertSuccess}
        handleClose={() => setOpenAlertSuccess(false)}
      />
    </>
  );
};

export default Form;
