import React from "react";
import styles from "./styles/GravitySimulator.module.scss";
import Button from "../../../Button";
import BackgroundImage from "../../../GravitySimulator/assets/background.jpg";
import PlanetImage_1x from "../../../GravitySimulator/assets/planet@1x.png";
import PlanetImage_2x from "../../../GravitySimulator/assets/planet@2x.png";
import PlanetImage_3x from "../../../GravitySimulator/assets/planet@3x.png";
import BotImage_1x from "../../../GravitySimulator/assets/bot@1x.png";
import BotImage_2x from "../../../GravitySimulator/assets/bot@2x.png";
import BotImage_3x from "../../../GravitySimulator/assets/bot@3x.png";

const GravitySimulator = () => (
  <div className={styles.root}>
    <img className={styles.background} src={BackgroundImage} alt="" />
    <img
      className={styles.planet}
      src={PlanetImage_1x}
      srcSet={`${PlanetImage_1x} 1x, ${PlanetImage_2x} 2x, ${PlanetImage_3x} 3x`}
      alt=""
    />
    <img
      className={styles.bot}
      src={BotImage_1x}
      srcSet={`${BotImage_1x} 1x, ${BotImage_2x} 2x, ${BotImage_3x} 3x`}
      alt=""
    />
    <div className={styles.filter} />
    <div className={styles.content}>
      <div className={styles.title}>GRAVITY SIMULATOR</div>
      {/*<div className={styles.category}>VISCERAL SCIENCE</div>*/}
      <Button path="gravity-simulator" isOpen={true} />
    </div>
  </div>
);

export default GravitySimulator;
