import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import AtomMaker from "../AtomMaker";
import EvolutionOfStars from "../EvolutionOfStars";
import GravitySimulator from "../GravitySimulator";
import NearTheSpeedOfLight from "../NearTheSpeedOfLight";
import styles from "./styles/Carousel.module.scss";
import { ReactComponent as ArrowImage } from "./assets/images/button_arrow.svg";

const projectsList = [
  NearTheSpeedOfLight,
  AtomMaker,
  EvolutionOfStars,
  GravitySimulator,
];

const PrevButton = ({ callback }) => {
  const swiper = useSwiper();

  return (
    <div
      className={classNames(styles.button, styles.previous)}
      onClick={() => {
        callback();
        swiper.slidePrev();
      }}
    >
      <ArrowImage />
    </div>
  );
};

const NextButton = ({ callback }) => {
  const swiper = useSwiper();

  return (
    <div
      className={classNames(styles.button, styles.next)}
      onClick={() => {
        callback();
        swiper.slideNext();
      }}
    >
      <ArrowImage />
    </div>
  );
};

const CarouselNew = () => {
  const [slideWidth, setSlideWidth] = useState(
    Math.round(window.innerWidth / 3.7),
  );
  const [slideOffset, setSlideOffset] = useState(
    Math.round(window.innerWidth / 3.7) / 2,
  );
  const [clickableSlides, setClickableSlides] = useState([]);
  const [open, setOpen] = useState(null);
  const [carouselTransition, setCarouselTransition] = useState(false);
  useEffect(() => {
    window.addEventListener("resize", () => {
      setSlideWidth(Math.round(window.innerWidth / 3.7));
      setSlideOffset(Math.round(window.innerWidth / 3.7) / 2);
    });
  }, []);

  const slides = new Array(projectsList.length * 2)
    .fill()
    .map((slide, index) => {
      const projectIndex = index % projectsList.length;
      const Component = projectsList[projectIndex];
      const key =
        Math.trunc(index / projectsList.length) !== 0
          ? `${Component.name}${Math.trunc(index / projectsList.length)}`
          : Component.name;

      return { key, Component };
    });

  const onIndexChange = (realIndex) => {
    if (realIndex >= 0 && realIndex <= 5) {
      setClickableSlides([realIndex, realIndex + 1, realIndex + 2]);
    }
    if (realIndex === 6) {
      setClickableSlides([6, 7, 0]);
    }
    if (realIndex === 7) {
      setClickableSlides([7, 0, 1]);
    }
  };

  const changeSlidesOffset = (offset) => {
    setSlideOffset(offset);
    setCarouselTransition(true);

    setTimeout(() => {
      setCarouselTransition(false);
    }, 500);
  };

  return (
    <div className={styles.root}>
      <Swiper
        slidesOffsetBefore={slideOffset}
        allowTouchMove={false}
        slidesPerView="auto"
        loop
        initialSlide={2}
        roundLengths
        className={styles.carousel}
        wrapperClass={carouselTransition && styles.carouselWrapper}
        onRealIndexChange={(element) => onIndexChange(element.realIndex)}
      >
        {slides.map(({ Component, key, style }, index) => (
          <SwiperSlide key={key} style={{ width: "auto" }}>
            <div
              className={styles.carouselItem}
              style={{ width: open === index ? slideWidth * 1.5 : slideWidth }}
              onClick={() => {
                const indexInArr = clickableSlides.indexOf(index);
                if (indexInArr === 2 && index !== open) {
                  changeSlidesOffset(-slideWidth / 4);
                } else if (indexInArr >= 0) {
                  changeSlidesOffset(slideWidth / 2);
                }
                // if (indexInArr === 1) {
                //   changeSlidesOffset(slideWidth / 4);
                // }
                // if (indexInArr === 0) {
                //   changeSlidesOffset(slideWidth);
                // }
                if (clickableSlides.includes(index)) {
                  setOpen(open === index ? null : index);
                }
              }}
            >
              <Component isOpen={open === index} />
            </div>
          </SwiperSlide>
        ))}
        <PrevButton callback={() => setOpen(null)} />
        <NextButton callback={() => setOpen(null)} />
      </Swiper>
    </div>
  );
};

export default CarouselNew;
