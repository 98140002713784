import React from "react";
import styles from "./styles/AtomMaker.module.scss";
import Button from "../../../Button";
import BackgroundImage from "../../../AtomMaker/assets/background.jpg";
import StarImage_1x from "../../../AtomMaker/assets/star@1x.png";
import StarImage_2x from "../../../AtomMaker/assets/star@2x.png";
import StarImage_3x from "../../../AtomMaker/assets/star@3x.png";
import TeacherImage_1x from "../../../AtomMaker/assets/teacher@1x.png";
import TeacherImage_2x from "../../../AtomMaker/assets/teacher@2x.png";
import TeacherImage_3x from "../../../AtomMaker/assets/teacher@3x.png";

const AtomMaker = () => (
  <div className={styles.root}>
    <img className={styles.background} src={BackgroundImage} alt="" />
    <img
      className={styles.star}
      src={StarImage_1x}
      srcSet={`${StarImage_1x} 1x, ${StarImage_2x} 2x, ${StarImage_3x} 3x`}
      alt=""
    />
    <img
      className={styles.teacher}
      src={TeacherImage_1x}
      srcSet={`${TeacherImage_1x} 1x, ${TeacherImage_2x} 2x, ${TeacherImage_3x} 3x`}
      alt=""
    />
    <div className={styles.filter} />
    <div className={styles.content}>
      <div className={styles.title}>ATOM MAKER</div>
      {/*<div className={styles.category}>VIRTUAL CLASSROOM</div>*/}
      <Button path="atom-maker" isOpen={true} />
    </div>
  </div>
);

export default AtomMaker;
