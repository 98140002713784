import React from "react";
import styles from "./styles/Contact.module.scss";
import Banner from "./components/Banner";
import Form from "./components/Form";

const Contact = () => (
  <div className={styles.root}>
    <Banner />
    <Form />
  </div>
);

export default Contact;
