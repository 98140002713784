import React from "react";
import styles from "./styles/Banner.module.scss";
import Fade from "react-reveal/Fade";

const Banner = () => (
  <div className={styles.root}>
    <Fade bottom>
      <div className={styles.title}>Contact us</div>
    </Fade>
  </div>
);

export default Banner;
