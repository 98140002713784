import React from "react";
import Banner from "./components/Banner";
import Summary from "./components/Summary";
import Details from "./components/Details";
import Gallery from "./components/Gallery";
import Navigation from "./components/Navigation";
import styles from "./styles/Application.module.scss";

// TODO: Refactor sections usage in Application render - id should not be duplicated
const sections = [
  {
    id: "Banner",
    title: "Banner",
    component: Banner,
  },
  {
    id: "Summary",
    title: "Summary",
    component: Summary,
  },
  {
    id: "Details",
    title: "Details",
    component: Details,
  },
  {
    id: "Gallery",
    title: "Gallery",
    component: Gallery,
  },
];

const Application = ({ data }) => (
  <div className={styles.root}>
    <Navigation sections={sections} />
    <Banner id="Banner" data={data.banner} />
    <Summary id="Summary" data={data.summary} />
    <Details id="Details" data={data.details} />
    <Gallery id="Gallery" data={data.gallery} />
  </div>
);

export default Application;
