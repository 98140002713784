import React, { useRef } from "react";
import styles from "./styles/CarouselMobile.module.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import classNames from "classnames";
import { ReactComponent as ArrowImage } from "./assets/images/button_arrow.svg";
import AtomMaker from "./components/AtomMaker";
import EvolutionOfStars from "./components/EvolutionOfStars";
import GravitySimulator from "./components/GravitySimulator";
import NearTheSpeedOfLight from "./components/NearTheSpeedOfLight";

const CarouselMobile = () => {
  const swiperRef = useRef();

  const projectsList = [
    { component: EvolutionOfStars, key: "EvolutionOfStars" },
    { component: GravitySimulator, key: "GravitySimulator" },
    { component: NearTheSpeedOfLight, key: "NearTheSpeedOfLight" },
    { component: AtomMaker, key: "AtomMaker" },
  ];

  return (
    <div className={styles.root}>
      <Swiper
        className={styles.swiper}
        slidesPerView={1}
        loop={true}
        onBeforeInit={(swiper) => {
          swiperRef.current = swiper;
        }}
      >
        {projectsList.map(({ component, key }) => (
          <SwiperSlide className={styles.slide} key={key}>
            {component}
          </SwiperSlide>
        ))}
      </Swiper>
      <div
        className={classNames(styles.button, styles.previous)}
        onClick={() => swiperRef.current?.slidePrev()}
      >
        <ArrowImage />
      </div>
      <div
        className={classNames(styles.button, styles.next)}
        onClick={() => swiperRef.current?.slideNext()}
      >
        <ArrowImage />
      </div>
    </div>
  );
};

export default CarouselMobile;
