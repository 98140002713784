import React from "react";
import { Link } from "react-scroll";
import classNames from "classnames";
import styles from "./styles/NavigationItem.module.scss";
import { ReactComponent as CircleOuter } from "./assets/images/circle_outer.svg";
import { ReactComponent as CircleInner } from "./assets/images/circle_inner.svg";

const NavigationItem = ({ id, active }) => (
  <Link className={styles.root} to={id} smooth spy offset={-94}>
    <div
      className={classNames(styles.point, {
        [styles.pointActive]: active,
      })}
    ></div>
    <div className={styles.circleOuter}>
      <CircleOuter />
    </div>
    <div className={styles.circleInner}>
      <CircleInner />
    </div>
  </Link>
);

export default NavigationItem;
