import React from "react";
import EvolutionOfStars from "./component/EvolutionOfStars";
import NearTheSpeedOfLight from "./component/NearTheSpeedOfLight";
import { Grid } from "@mui/material";
import styles from "./styles/WebApplications.module.scss";

const WebApplications = () => (
  <div className={styles.root}>
    <div className={styles.container}>
      <div className={styles.applications}>
        <Grid container justifyContent="center" spacing={3}>
          <Grid item xs={12} lg={6}>
            <EvolutionOfStars />
          </Grid>
          <Grid item xs={12} lg={6}>
            <NearTheSpeedOfLight />
          </Grid>
        </Grid>
      </div>
      <div className={styles.backgroundText}>WEB APPLICATIONS</div>
    </div>
  </div>
);

export default WebApplications;
