import React, { useEffect, useState } from "react";
import { Element } from "react-scroll";
import Fade from "react-reveal/Fade";
import styles from "./styles/Gallery.module.scss";
import classNames from "classnames";

const Video = ({ src }) => {
  useEffect(() => {
    const canHover = window.matchMedia("(hover: hover)").matches;
    setCanHover(canHover);
  }, []);
  const [canHover, setCanHover] = useState(true);
  const [videoIsPlaying, setVideoIsPlaying] = useState(false);

  const playVideo = (e) => {
    e.target.play();
  };
  const pauseVideo = (e) => {
    e.target.pause();
  };

  const onClickVideo = (e) => {
    if (videoIsPlaying) {
      setVideoIsPlaying(false);
      e.target.pause();
    }
    if (!videoIsPlaying) {
      setVideoIsPlaying(true);
      e.target.play();
    }
  };

  return (
    <video
      src={`${src}#t=0.001`}
      onMouseOver={canHover ? playVideo : null}
      onMouseOut={canHover ? pauseVideo : null}
      onClick={!canHover ? onClickVideo : null}
      controls={false}
      loop
      playsInline
      muted
    ></video>
  );
};

const Gallery = ({ id, data }) => (
  <Element name={id} id={id}>
    <div className={styles.root}>
      <div className={styles.container}>
        <Fade top>
          <div className={styles.title}>Gallery</div>
        </Fade>
        <Fade bottom fraction={0.01}>
          <div className={styles.videos}>
            <div className={styles.column}>
              <div className={classNames([styles.video, styles.video1])}>
                <Video src={data.video_1} />
              </div>
              <div className={classNames([styles.video, styles.video2])}>
                <Video src={data.video_2} />
              </div>
            </div>
            <div className={styles.column}>
              <div className={classNames([styles.video, styles.video3])}>
                <Video src={data.video_3} />
              </div>
              <div className={classNames([styles.video, styles.video4])}>
                <Video src={data.video_4} />
              </div>
            </div>
            <div className={styles.column}>
              <div className={classNames([styles.video, styles.video5])}>
                <Video src={data.video_5} />
              </div>
              <div className={classNames([styles.video, styles.video6])}>
                <Video src={data.video_6} />
              </div>
            </div>
          </div>
        </Fade>
        <div className={styles.backgroundText}>Gallery</div>
      </div>
    </div>
  </Element>
);

export default Gallery;
