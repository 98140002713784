import React, { useEffect, useState } from "react";
import isMobile from "ismobilejs";
import { useParams, NavLink } from "react-router-dom";
import VisceralScienceInstructionsStage2 from "./assets/images/instructions/visceral-science/instructions_stage_2.png";
import VisceralScienceInstructionsStage3 from "./assets/images/instructions/visceral-science/instructions_stage_3.png";
import NearTheSpeedOfLightInstructionsStage2 from "./assets/images/instructions/near-the-speed-of-light/instructions_stage_2.png";
import AppStoreButton from "./assets/images/app_store.png";
import GooglePlayButton from "./assets/images/google_play.png";
import { ReactComponent as LeftArrow } from "./assets/images/left-arrow.svg";
import styles from "./styles/WebApplication.module.scss";

const INSTRUCTIONS_STAGE_IMAGES = {
  "stellar-simulator": {
    INSTRUCTIONS_STAGE_1: null,
    INSTRUCTIONS_STAGE_2: VisceralScienceInstructionsStage2,
    INSTRUCTIONS_STAGE_3: VisceralScienceInstructionsStage3,
  },
  "light-speed-simulator": {
    INSTRUCTIONS_STAGE_1: null,
    INSTRUCTIONS_STAGE_2: NearTheSpeedOfLightInstructionsStage2,
  },
};

const WEB_APPLICATION_URL = {
  "stellar-simulator": "https://webgl.abelanavr.com/VS/Stage/0.14.4/",
  "light-speed-simulator": "https://webgl.abelanavr.com/NSoL/Stage/0.013/",
};

const APPLICATION_URL_APP_STORE = {
  "stellar-simulator":
    "https://apps.apple.com/pl/app/visceral-science/id1494958666",
  "light-speed-simulator":
    "https://apps.apple.com/pl/app/near-the-speed-of-light/id6466729484",
};

const APPLICATION_URL_PLAY_MARKET = {
  "stellar-simulator":
    "https://play.google.com/store/apps/details?id=com.abelanavr.visceralsciencear&pcampaignid=web_share",
  "light-speed-simulator":
    "https://play.google.com/store/apps/details?id=com.abelanavr.graspingthespeedoflight",
};

// TODO: Add redirect to index when application is not on the list
const WebApplication = () => {
  const { gameSlug } = useParams();

  const [isSupported, setIsSupported] = useState(true);
  const [instructionsImage, setInstructionsImage] = useState(
    INSTRUCTIONS_STAGE_IMAGES[gameSlug]["INSTRUCTIONS_STAGE_1"],
  );

  useEffect(() => {
    const { phone, tablet } = isMobile(window.navigator.userAgent);
    setIsSupported(!(phone || tablet));

    const onMessageReceive = ({ data }) => {
      if (data?.includes?.("INSTRUCTIONS_STAGE_")) {
        setInstructionsImage(INSTRUCTIONS_STAGE_IMAGES[gameSlug][data]);
      }
    };

    window.addEventListener("message", onMessageReceive);

    return () => window.removeEventListener("message", onMessageReceive);
  }, []);

  return isSupported ? (
    <div className={styles.root}>
      <div className={styles.iframeContainer}>
        <iframe
          className={styles.iframe}
          src={WEB_APPLICATION_URL[gameSlug]}
          title={gameSlug}
          id="iframe"
          allow="camera;microphone;fullscreen;accelerometer;gyroscope"
        ></iframe>
      </div>
      <div className={styles.instructionsContainer}>
        {instructionsImage && <img src={instructionsImage} alt="" />}
      </div>
      <NavLink to="/web-applications" className={styles.backButton}>
        <LeftArrow /> Back to Apps
      </NavLink>
    </div>
  ) : (
    <div className={styles.root}>
      <div className={styles.storeTitle}>DOWNLOAD MOBILE APP</div>
      <div className={styles.storeDescription}>
        You can download mobile version of this application from one of the
        following stores
      </div>
      <div className={styles.storeContainer}>
        <a
          className={styles.storeLink}
          href={APPLICATION_URL_APP_STORE[gameSlug]}
          target="_blank"
          rel="noreferrer"
        >
          <img src={AppStoreButton} alt="" />
        </a>
        <a
          className={styles.storeLink}
          href={APPLICATION_URL_PLAY_MARKET[gameSlug]}
          target="_blank"
          rel="noreferrer"
        >
          <img src={GooglePlayButton} alt="" />
        </a>
      </div>
      <NavLink to="/web-applications" className={styles.backButton}>
        <LeftArrow /> Back to Apps
      </NavLink>
    </div>
  );
};

export default WebApplication;
