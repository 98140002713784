import backgroundVideo from "./videos/Banner/background_video.mp4";
import snapshot from "./images/Banner/snapshot.png";
import summary_image_1x from "./images/Summary/image@1x.png";
import summary_image_2x from "./images/Summary/image@2x.png";
import summary_image_3x from "./images/Summary/image@3x.png";
import details_image_1x from "./images/Details/image@1x.png";
import details_image_2x from "./images/Details/image@2x.png";
import details_image_3x from "./images/Details/image@3x.png";
import gallery_video_1 from "./videos/Gallery/video_1.mp4";
import gallery_video_2 from "./videos/Gallery/video_2.mp4";
import gallery_video_3 from "./videos/Gallery/video_3.mp4";
import gallery_video_4 from "./videos/Gallery/video_4.mp4";
import gallery_video_5 from "./videos/Gallery/video_5.mp4";
import gallery_video_6 from "./videos/Gallery/video_6.mp4";

export const atomMaker = {
  banner: {
    title: "Atom Maker",
    snapshot: snapshot,
    backgroundVideo: backgroundVideo,
    downloadLink: "https://app.abelanavr.com/catalog/1",
    buttonTitle: "Download",
  },
  summary: {
    text: "This virtual experience is dedicated to the topic of atoms, orbitals, and the periodic table of elements. Students will be able to assemble all known elements, visualize bizarre shapes of orbitals across energy levels of an atom, improve their understanding of familiar diagrams from the school curriculum, compete against each other in educational games that help to solidify the material and enjoy the best blend of virtual reality and peer-driven learning.",
    image_1x: summary_image_1x,
    image_2x: summary_image_2x,
    image_3x: summary_image_3x,
  },
  details: {
    image_1x: details_image_1x,
    image_2x: details_image_2x,
    image_3x: details_image_3x,
    userEnvironment: "Multiplayer",
    maximumClassSize: "32",
    availableRoles: "Teacher, students",
    pcVrVersion: "Yes",
    standaloneVrVersion: "No",
    mobileApp: "Yes (teacher)",
    webApplication: null,
    videoChat: "No",
  },
  gallery: {
    video_1: gallery_video_1,
    video_2: gallery_video_2,
    video_3: gallery_video_3,
    video_4: gallery_video_4,
    video_5: gallery_video_5,
    video_6: gallery_video_6,
  },
};
