import React from "react";
import styles from "./styles/AlertError.module.scss";
import { Alert, Snackbar } from "@mui/material";

const AlertError = ({ open, handleClose }) => (
  <Snackbar
    open={open}
    autoHideDuration={6000}
    onClose={handleClose}
    anchorOrigin={{
      horizontal: "center",
      vertical: "top",
    }}
    classes={{
      root: styles.snackbar,
    }}
  >
    <Alert
      severity="error"
      classes={{
        root: styles.alert,
        icon: styles.icon,
        message: styles.message,
      }}
    >
      Oops! We are sorry, something went wrong. If this error persists, just
      email us directly at{" "}
      <a href="mailto: dev@abelanavr.com">dev@abelanavr.com</a>!
    </Alert>
  </Snackbar>
);

export default AlertError;
