import React from "react";
import classNames from "classnames";
import NavigationItem from "./components/NavigationItem";
import styles from "./styles/Navigation.module.scss";

class Navigation extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      intersectionStatus: props.sections.reduce(
        (obj, item) => ({
          ...obj,
          [item.id]: item,
        }),
        {},
      ),
      active: props.sections[0].id,
      firstVisible: true,
      lastVisible: true,
      scrollEventSet: false,
    };

    this.ioOptions = {
      marginRoot: "-106px 0px 0px 0px",
      threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
    };

    this.scrollContainerRef = React.createRef();

    console.log(props.sections);
    this.linksPointerArray = props.sections.map(
      (section) => `Link${section.id}`,
    );
  }

  setIntersectionStatus = (intersectionStatus, callback) =>
    this.setState({ intersectionStatus }, callback);
  setActive = (active, callback) => this.setState({ active }, callback);
  setFirstVisible = (firstVisible, callback) =>
    this.setState({ firstVisible }, callback);
  setLastVisible = (lastVisible, callback) =>
    this.setState({ lastVisible }, callback);
  setScrollEventSet = (scrollEventSet, callback) =>
    this.setState({ scrollEventSet }, callback);

  componentDidMount() {
    const { sections } = this.props;

    /**
     * Scroll behavior related code
     */
    const observer = new IntersectionObserver(this.ioCallback, this.ioOptions);
    sections.map(({ id }) => observer.observe(document.getElementById(id)));
  }

  // TODO: Not perfect solution, this should be fired in componentDidMount but need to fix ref issue
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { scrollEventSet } = this.state;
    const scrollContainer = this.scrollContainerRef.current;

    if (!scrollEventSet && scrollContainer) {
      this.setScrollEventSet({ scrollEventSet: true }, () => {
        scrollContainer.addEventListener("scroll", this.onScroll);
        scrollContainer.dispatchEvent(new Event("scroll"));
      });
    }
  }

  componentWillUnmount() {
    const scrollContainer = this.scrollContainerRef.current;

    if (!scrollContainer) {
      return;
    }

    scrollContainer.removeEventListener("scroll", this.onScroll);

    this.observer && this.observer.disconnect();
  }

  onScroll = (event) => {
    const isFirstVisible = event.target.scrollLeft === 0;
    const isLastVisible =
      event.target.scrollLeft ===
      event.target.scrollWidth - event.target.offsetWidth;

    this.setFirstVisible(isFirstVisible);
    this.setLastVisible(isLastVisible);
  };

  getMaxIntersection = () => {
    const { intersectionStatus } = this.state;

    return Object.keys(intersectionStatus).reduce((maxSectionID, sectionID) =>
      intersectionStatus[maxSectionID] >= intersectionStatus[sectionID]
        ? maxSectionID
        : sectionID,
    );
  };

  ioCallback = (entries) => {
    const { intersectionStatus } = this.state;

    const updatedIntersectionStatus = {
      ...intersectionStatus,
    };
    entries.forEach((entry) => {
      updatedIntersectionStatus[entry.target.id] = entry.intersectionRatio;
    });

    this.setIntersectionStatus(updatedIntersectionStatus, () => {
      const maxSectionKey = this.getMaxIntersection();
      this.setActive(maxSectionKey, () => {
        const scrollContainer = this.scrollContainerRef.current;

        if (scrollContainer) {
          const elementId = `Link${this.state.active}`;
          const scrollTarget = document.getElementById(elementId);
          const scrollTargetIndex = this.linksPointerArray.indexOf(elementId);
          const isFirst = scrollTargetIndex === 0;
          const isLast =
            scrollTargetIndex === this.linksPointerArray.length - 1;

          const leftOffset = isFirst || isLast ? 16 : 48;

          scrollContainer.scrollTo({
            left: scrollTarget.offsetLeft - leftOffset,
            behavior: "smooth",
          });
        }
      });
    });
  };

  render() {
    const { className, sections } = this.props;
    const { active, firstVisible, lastVisible } = this.state;

    return (
      <div
        className={classNames(
          styles.root,
          {
            [styles.fadeLeft]: !firstVisible,
            [styles.fadeRight]: !lastVisible,
          },
          className,
        )}
        id="infoNavigationRoot"
      >
        <div className={styles.container} ref={this.scrollContainerRef}>
          {sections.map(({ id, title }) => (
            <div id={`Link${id}`} key={id}>
              <NavigationItem active={id === active} id={id} />
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default Navigation;
