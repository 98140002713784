import backgroundVideo from "./videos/Banner/background_video.mp4";
import snapshot from "./images/Banner/snapshot.png";
import summary_image_1x from "./images/Summary/image@1x.png";
import summary_image_2x from "./images/Summary/image@2x.png";
import summary_image_3x from "./images/Summary/image@3x.png";
import details_image_1x from "./images/Details/image@1x.png";
import details_image_2x from "./images/Details/image@2x.png";
import details_image_3x from "./images/Details/image@3x.png";
import gallery_video_1 from "./videos/Gallery/video_1.mp4";
import gallery_video_2 from "./videos/Gallery/video_2.mp4";
import gallery_video_3 from "./videos/Gallery/video_3.mp4";
import gallery_video_4 from "./videos/Gallery/video_4.mp4";
import gallery_video_5 from "./videos/Gallery/video_5.mp4";
import gallery_video_6 from "./videos/Gallery/video_6.mp4";

export const nearTheSpeedOfLight = {
  banner: {
    title: "LIGHT SPEED SIMULATOR",
    snapshot: snapshot,
    backgroundVideo: backgroundVideo,
    downloadLink:
      "https://store.steampowered.com/app/3060380/Light_Speed_Simulator/",
    buttonTitle: "COMING SOON",
  },
  summary: {
    text: "This is the next installment in the Simulators series, spearheaded by the World Science Festival. The virtual reality application helps students acquire an intuitive understanding of what happens when we move closer to the speed of light. The experience is an innovative and engaging crossplay where a team of mobile players competes against players in virtual reality, incorporating phenomena such as the Relativistic Doppler Effect, the Headlight Effect, Lorentz Contraction, and the Relativity of Simultaneity.",
    image_1x: summary_image_1x,
    image_2x: summary_image_2x,
    image_3x: summary_image_3x,
  },
  details: {
    image_1x: details_image_1x,
    image_2x: details_image_2x,
    image_3x: details_image_3x,
    userEnvironment: "Multiplayer",
    maximumClassSize: "34",
    availableRoles: "VR / mobile crossplay",
    pcVrVersion: "Yes",
    standaloneVrVersion: "Yes",
    mobileApp: "Yes (crossplay)",
    webApplication: "/web-applications/light-speed-simulator",
    videoChat: "No",
  },
  gallery: {
    video_1: gallery_video_1,
    video_2: gallery_video_2,
    video_3: gallery_video_3,
    video_4: gallery_video_4,
    video_5: gallery_video_5,
    video_6: gallery_video_6,
  },
};
