import React from "react";
import styles from "./styles/AlertSuccess.module.scss";
import { Alert, Snackbar } from "@mui/material";

const AlertSuccess = ({ open, handleClose }) => (
  <Snackbar
    open={open}
    autoHideDuration={6000}
    onClose={handleClose}
    anchorOrigin={{
      horizontal: "center",
      vertical: "top",
    }}
    classes={{
      root: styles.snackbar,
    }}
  >
    <Alert
      severity="success"
      classes={{
        root: styles.alert,
        icon: styles.icon,
        message: styles.message,
      }}
    >
      Your message was successfully sent!
    </Alert>
  </Snackbar>
);

export default AlertSuccess;
