import React from "react";
import BackgroundImage from "./assets/background.jpg";
import BotImage_1x from "./assets/bot@1x.png";
import BotImage_2x from "./assets/bot@2x.png";
import BotImage_3x from "./assets/bot@3x.png";
import ShipBlueImage_1x from "./assets/ship_blue@1x.png";
import ShipBlueImage_2x from "./assets/ship_blue@2x.png";
import ShipBlueImage_3x from "./assets/ship_blue@3x.png";
import ShipOrangeImage_1x from "./assets/ship_orange@1x.png";
import ShipOrangeImage_2x from "./assets/ship_orange@2x.png";
import ShipOrangeImage_3x from "./assets/ship_orange@3x.png";
import ShipVioletImage_1x from "./assets/ship_violet@1x.png";
import ShipVioletImage_2x from "./assets/ship_violet@2x.png";
import ShipVioletImage_3x from "./assets/ship_violet@3x.png";
import styles from "./styles/NearTheSpeedOfLight.module.scss";
import Button from "../Button";
import classNames from "classnames";

const NearTheSpeedOfLight = ({ isOpen }) => (
  <div
    className={classNames({
      [styles.root]: true,
      [styles.open]: isOpen,
    })}
  >
    <img className={styles.background} src={BackgroundImage} alt="" />
    <img
      className={styles.shipBlue}
      src={ShipBlueImage_1x}
      srcSet={`${ShipBlueImage_1x} 1x, ${ShipBlueImage_2x} 2x, ${ShipBlueImage_3x} 3x`}
      alt=""
    />
    <img
      className={styles.shipOrange}
      src={ShipOrangeImage_1x}
      srcSet={`${ShipOrangeImage_1x} 1x, ${ShipOrangeImage_2x} 2x, ${ShipOrangeImage_3x} 3x`}
      alt=""
    />
    <img
      className={styles.shipViolet}
      src={ShipVioletImage_1x}
      srcSet={`${ShipVioletImage_1x} 1x, ${ShipVioletImage_2x} 2x, ${ShipVioletImage_3x} 3x`}
      alt=""
    />
    <img
      className={styles.bot}
      src={BotImage_1x}
      srcSet={`${BotImage_1x} 1x, ${BotImage_2x} 2x, ${BotImage_3x} 3x`}
      alt=""
    />
    <div className={styles.filter} />
    <div className={styles.content}>
      <div className={styles.title}>LIGHT SPEED SIMULATOR</div>
      {/*<div className={styles.category}>VISCERAL SCIENCE</div>*/}
      <Button path="light-speed-simulator" isOpen={isOpen} />
    </div>
    <div className={styles.line} />
  </div>
);

export default NearTheSpeedOfLight;
