import React from "react";
import { Element } from "react-scroll";
import Fade from "react-reveal/Fade";
import styles from "./styles/Banner.module.scss";
import { ReactComponent as ButtonBorder } from "./assets/images/button_border.svg";

const Banner = ({ id, data }) => (
  <Element name={id} id={id}>
    <div className={styles.root}>
      <video
        className={styles.backgroundVideo}
        src={data.backgroundVideo}
        autoPlay
        controls={false}
        loop
        playsInline
        muted
        poster={data.snapshot}
      ></video>
      <div className={styles.filter} />
      <div className={styles.container}>
        <Fade left>
          <div className={styles.title}>{data.title}</div>
        </Fade>
        <Fade bottom>
          <a
            href={data.downloadLink}
            target="_blank"
            rel="nofollow noopener noreferrer"
            className={styles.buttonContainer}
          >
            <div
              className={styles.button}
              style={{
                ...(data.buttonTitle === "COMING SOON" && {
                  padding: "13.5px 77.5px",
                }),
              }}
            >
              {data.buttonTitle}
            </div>
            <div className={styles.buttonBorder}>
              <ButtonBorder />
            </div>
          </a>
        </Fade>
        <div className={styles.line} />
      </div>
    </div>
  </Element>
);

export default Banner;
