import React, { useEffect } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./styles/index.scss";
import styles from "./App.module.scss";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import WebApplications from "./pages/WebApplications";
import WebApplication from "./pages/WebApplication";
import About from "./pages/About";
import Application from "./pages/Application";
import { atomMaker } from "./applicationsData/AtomMaker";
import { evolutionOfStars } from "./applicationsData/EvolutionOfStars";
import { gravitySimulator } from "./applicationsData/GravitySimulator";
import { nearTheSpeedOfLight } from "./applicationsData/NearTheSpeedOfLight";
import Contact from "./pages/Contact";
import ScrollToTop from "./components/ScrollToTop";

const applications = [
  {
    path: "atom-maker",
    data: atomMaker,
  },
  {
    path: "stellar-simulator",
    data: evolutionOfStars,
  },
  {
    path: "gravity-simulator",
    data: gravitySimulator,
  },
  {
    path: "light-speed-simulator",
    data: nearTheSpeedOfLight,
  },
];

const App = () => {
  useEffect(() => {
    const onPageLoad = () => {
      console.log("All resources finished loading!");
    };
    if (document.readyState === "complete") {
      onPageLoad();
    } else {
      window.addEventListener("load", onPageLoad);
      return () => window.removeEventListener("load", onPageLoad);
    }
  }, []);

  return (
    <BrowserRouter>
      <Header />
      <div className={styles.container}>
        <div className={styles.content}>
          <Routes>
            <Route path="" element={<Home />} />
            <Route path="about" element={<About />} />
            <Route path="contact" element={<Contact />} />
            <Route path="web-applications">
              <Route index element={<WebApplications />} />
              <Route path=":gameSlug" element={<WebApplication />} />
            </Route>
            <Route path="terms-of-use" element={<div>terms-of-use</div>} />
            <Route path="privacy-policy" element={<div>privacy-policy</div>} />
            {applications.map((app) => (
              <Route
                path={app.path}
                element={<Application data={app.data} />}
              />
            ))}
            <Route path="*" element={<Navigate to="" replace />} />
            {/*<Route path="*" element={<div>404</div>} />*/}
          </Routes>
        </div>
        <Footer />
      </div>
      <ScrollToTop />
    </BrowserRouter>
  );
};

export default App;
